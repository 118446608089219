<template>
  <div class="programBox">
    <el-row style="width: 100%">
      <el-col :span="24" class="programBackImg">
        <el-image :src="programBackImg" fit="cover" style="width: 100%; height: 100%;cursor: pointer;"></el-image>
      </el-col>
    </el-row>

    <!-- 新闻资讯  -->
    <div class="program-1200">
      <div class="titleBox">
        <el-divider>新闻资讯</el-divider>
      </div>

      <el-row :gutter="18" style="width: 100%;">
        <el-col :span="8" v-for="(res) in journalismList">
          <div class="programLi" @click="newDetails(res.id)">
            <div class="programImg"><el-image :src="res.coverImg" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image></div>
            <div class="programInfo">
              <div class="programName line1">{{res.title}}</div>
              <div class="programTime line1">发布时间：{{formatDate(res.releaseTime)}}</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 分页 -->
      <div class="paginationBox verticals">
      	<el-pagination background :page-size="queryParams.pageSize" layout="prev, pager, next" @size-change="changeSize" @current-change="changeCurrent" :total="total"></el-pagination>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { request } from '@/utils/request';
  export default {
    components: {},
    data() {
      return {
        programBackImg:"http://djs.thinkerwork.com/20230824144117412_未标题-1@2x.png",
        //新闻列表
        journalismList:[],
        //总条数
        total:0,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 12,
        },
      };
    },
    created() {
      this.getList();
    },
    methods: {
      /* 新闻列表 */
      getList(){
        request('https://xinke.thinkervc.cn/api/web/activityCms/getActivityCmsList?pageNum=' + this.queryParams.pageNum + '&pageSize=' +this.queryParams.pageSize,{},{method: 'get'}).then(res => {
          if (res.code === 200) {
           this.journalismList = res.data.records;
           this.total = res.data.total;
          }
        });
      },
      /* 时间转成年月日 */
      formatDate(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      //分页监听
      changeSize(val){
      	this.queryParams.pageNum = val;
      	this.getList();
      },
      //分页监听
      changeCurrent(val){
      	this.queryParams.pageNum = val;
      	this.getList();
      },
      /* 跳转详情 */
      newDetails(id){
      	this.$router.push({ name: 'NewsDetails', query: {id:id} });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .programBox{
    width: 100%;
    min-height: 100vh;
  }
  .programBackImg{
    width: 100%;
    height: 360px;
    margin-bottom: 80px;
  }
  .program-1200{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  .titleBox{
    padding: 0 350px;
    margin-bottom: 40px;
    overflow: hidden;
  }
  ::v-deep .el-divider__text{
    font-size: 30px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    color: #000000;
    letter-spacing: 1.6px;
  }
  ::v-deep .el-divider {
      background-color: #F08307;
  }
  .programLi{
    width: 100%;
    height: 330px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px #dbdbdb;
    cursor: pointer;
    margin-bottom: 16px;
  }
  .programImg{
    width: 100%;
    height: 220px;
  }
  .programInfo{
    width: 100%;
    padding: 20px 14px;
  }
  .programName{
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    letter-spacing: 0.82px;
    margin-bottom: 15px;
  }
  .programTime{
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #999999;
    letter-spacing: 0.82px;
    margin-bottom: 15px;
  }
  .line1 {
  	overflow: hidden;
  	text-overflow: ellipsis;
  	white-space: nowrap;
  }
  .paginationBox{
  	width: 100%;
  	height: 80px;
    margin-top: 60px;
  }
  .verticals{
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }

  @media screen and (max-width: 1200px) {}
</style>
